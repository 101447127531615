import {
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  useTheme,
  CircularProgress,
  Checkbox,
  TextField,
  LinearProgress,
  Box,
  Typography,
  Stack,
  TableCellProps,
  Chip,
  FormControl,
  Select,
} from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdLocationOn, MdTimer, MdWarning } from 'react-icons/md';
import Button from 'components/Button';
import ErrorSnackbar from 'components/ErrorSnackbar';
import { ConfirmDialogVariant, FormDialogState } from 'components/FormDialog';
import { Hide } from 'components/Hide';
import IconButton from 'components/IconButton';
import LazyFormDialog from 'components/LazyFormDialog';
import { QualityChipVariant, QualityChips } from 'components/QualityChip';
import TableCellImage from 'components/TableCellImage';
import { defaultFormDialogState } from 'components/default-form-dialog-state';
import { BaseTheme, baseTheme } from 'config/base-theme';
import { useFeatures } from 'hooks/useFeatures';
import useHandleCustomSchemeAndQuery from 'hooks/useHandleCustomSchemeAndQuery';
import useIsViewport from 'hooks/useIsViewport';
import useUser from 'hooks/useUser';
import { mergeSx } from 'utils/merge-sx';
import {
  ExpandableTableRow,
  FontStyle,
  FontType,
  RowDataType,
  Shipment,
  TableCell as TableCellData,
  TableCellType,
  TableColumn,
  TableType,
  TableAction,
  Action,
  Button as ButtonData,
} from 'generated/graphql';
import ActionList from '../ActionList';
import NumberFormatRands from '../NumberFormatRands';
import ProductIcons from '../ProductIcons';
import TableDialog from '../TableDialog';
import BankDepositDetailsDialog from './BankDepositDetailsDialog';
import EditHistoryRowDetail from './EditHistoryRowDetail';
import PriceTableRowDetail from './PriceTableRowDetail';
import ReportTableRowDetail from './ReportTableRowDetail';
import ShipmentRowDetail from './ShipmentRowDetail';
import SubTable from './SubTable';
import TableActionBar from './TableActionBar';

enum ReservedButtonValue {
  Warning = 'Warning',
  Pending = 'Pending',
}

const stickyColumnStyle = {
  position: 'sticky',
  left: 0,
  zIndex: 1,
  boxShadow: 'inset -10px 0 1px -10px rgba(0,0,0,0.4)',
  borderBottom: 'none',
  borderRight: 'none',
};
const stickyRowStyle = (index: number) => ({
  position: 'sticky',
  top: 0,
  zIndex: index === 0 ? 3 : 2,
  boxShadow: 'inset 0 -10px 1px -10px rgba(0,0,0,0.4)',
  borderBottom: 'none',
});

const Cell = ({ sx, ...rest }: TableCellProps) => (
  <TableCell
    {...rest}
    sx={mergeSx(
      (theme) => ({
        '&.MuiTableCell-root': {
          padding: theme.spacing(0.5, 1.25),
          overflowWrap: 'break-word',
          wordBreak: 'break-word',
          hyphens: 'auto',
          minWidth: 60,
          [theme.breakpoints.down('sm')]: {
            width: 100,
            padding: theme.spacing(0.5, 0.5),
          },
        },
      }),
      sx ?? {},
    )}
  />
);

const ActionCell = ({ sx, ...rest }: TableCellProps) => (
  <TableCell
    {...rest}
    sx={mergeSx(
      (theme) => ({
        '&.MuiTableCell-root': {
          borderRight: 0,
          borderLeft: 0,
          padding: theme.spacing(0.75, 1.5),
          [theme.breakpoints.down('md')]: {
            padding: theme.spacing(0.75, 1, 0.75, 0),
          },
        },
      }),
      sx ?? {},
    )}
  />
);
export interface CellStyle {
  theme: BaseTheme;
  cellData: TableCellData;
  type: TableType;
  hideLastBorder?: boolean;
  isNested?: boolean;
  shiftRight?: boolean;
  hideLeftBorder?: boolean;
  hideRightBorder?: boolean;
  isInline?: boolean;
  index: number;
}

const cellStyle = (style: CellStyle) => {
  const theme = style.theme;
  const cellData = style.cellData;
  const type = style.type;
  const hideLastBorder = style.hideLastBorder;
  const isNested = style.isNested;
  const shiftRight = style.shiftRight;
  const hideLeftBorder = style.hideLeftBorder;
  const hideRightBorder = style.hideRightBorder;
  const isInline = style.isInline;

  return {
    ...(type === TableType.Statistics && {
      '&.MuiTableCell-root': {
        borderRight: hideRightBorder ? 'hidden' : '',
        borderLeft: hideLeftBorder ? 'hidden' : '',
        background: !isNested && theme.palette.tertiary.light,
        paddingLeft: shiftRight ? theme.spacing(2) : '',
        verticalAlign: 'bottom',
        paddingY: theme.spacing(1),
        textAlign: cellData.align,
        ...(style.index === 0 && stickyColumnStyle),
        display: isInline ? 'flex' : '',
        gap: 1,
      },
    }),
    ...(cellData.fontStyle === FontStyle.Bold && { fontWeight: theme.typography.fontWeightMedium }),
    ...(cellData.fontStyle === FontStyle.Badge && {
      padding: theme.spacing(0.4, 1),
      margin: theme.spacing(1),
      backgroundColor: theme.palette.primary.light,
      borderRadius: theme.spacing(2),
    }),
    ...(cellData.fontStyle === FontStyle.Highlighted && {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.light,
    }),
    ...((cellData.fontType === FontType.Normal || cellData.fontStyle === FontStyle.Badge) && {
      fontFamily: theme.typography.fontFamily,
    }),
    ...(cellData.fontType === FontType.Mono && { fontFamily: 'Roboto Mono' }),
    ...(hideLastBorder && { border: 0 }),
    ...(type === TableType.Reporting && { borderBottom: `2px solid ${theme.palette.background.default}` }),
    ...(type !== TableType.Detail &&
      type !== TableType.Reporting && { borderBottom: `1px solid ${theme.palette.common.white}` }),
  };
};

export const Row = ({
  data: { cells },
  nestedData,
  isNested = false, // indicates if the row is nested inside of another row
  type,
  refresh,
  hideLastBorder = false,
  data,
  isFetching = false,
  showCheckbox = false,
  checked = false,
  highlighted = false,
  initExpanded = false,
  onCheckboxToggle,
  onFieldChange,
  hasPending,
  onCustomSchemeRequest = () => {},
  setShowGraphs = () => {},
}: {
  isFetching?: boolean;
  hasPending?: boolean;
  data: TableRowData;
  nestedData?: TableRowData[];
  isNested?: boolean;
  type: TableType;
  refresh: () => void;
  hideLastBorder?: boolean;
  highlighted?: boolean;
  showCheckbox?: boolean;
  checked?: boolean;
  initExpanded?: boolean;
  onCheckboxToggle?(newValue: boolean): void;
  onFieldChange?(cellIndex: number, newValue: string): void;
  onCustomSchemeRequest?(url: string): void;
  setShowGraphs?(showGraphs: boolean): void;
}) => {
  const [open, setOpen] = useState(false);

  const [buttonActionsOpen, setButtonActionsOpen] = useState<ButtonData | undefined>(undefined);
  const [actionsLoading, setActionsLoading] = useState(new Array(cells.length).fill(false));
  const [actionsIndex, setActionsIndex] = useState<number>();
  const [formDialog, setFormDialog] = useState<FormDialogState>(defaultFormDialogState);

  const { isSeller } = useUser();
  const isStatisticalType = type === TableType.Statistics;
  const isPriceTableRowDetail = isSeller && isStatisticalType && data.dataType === RowDataType.Rows;

  const { isEnabled } = useFeatures();
  const isDispatchAndDocsRedesignEnabled = isEnabled('DispatchAndDocsRedesign');

  const isReportingType = type === TableType.Reporting;
  const isReportTableRowDetail = isReportingType && data.dataType === RowDataType.Report;
  const isBasicType = isDispatchAndDocsRedesignEnabled && type === TableType.Basic;

  const { handleSchemeOrQuery, errorMessage, isLoading, paymentDetails, tableDialog, setErrorMessage } =
    useHandleCustomSchemeAndQuery(refresh, onCustomSchemeRequest);
  const clearError = () => setErrorMessage(undefined);

  useEffect(() => {
    if (actionsIndex && actionsLoading[actionsIndex] !== isLoading) {
      const actionsLoadingCopy = [...actionsLoading];
      actionsLoadingCopy[actionsIndex] = isLoading;
      setActionsLoading(actionsLoadingCopy);

      if (!isLoading) {
        setActionsIndex(undefined);
      }
    }
  }, [isLoading, actionsIndex, actionsLoading]);

  useEffect(() => {
    setOpen(initExpanded || false);
  }, [initExpanded]);

  const isExpandable = data.dataType !== RowDataType.None;

  const handleRowClick = () => {
    if (!isExpandable) {
      return;
    }
    setOpen((value) => !value);
  };

  return (
    <>
      <TableRow
        sx={(theme) => ({
          cursor: 'pointer',
          margin: theme.spacing(1, 0),
          ...(highlighted && { background: theme.palette.tertiary?.main }),
          ...(type === TableType.Detail || isBasicType
            ? { background: theme.palette.background.paper }
            : isReportingType
              ? { background: theme.palette.tertiary.main }
              : {
                  background: theme.palette.tertiary?.light,
                  [theme.breakpoints.down('md')]: {
                    background: theme.palette.tertiary?.main,
                  },
                }),
        })}
        onClick={handleRowClick}
      >
        {cells.map((cellData, i) => {
          const hideLeftBorder = i === 0;
          const hideRightBorder = i > 0;
          const shiftRight = isNested && i === 0;
          const isInline = !isNested && i === 0;

          let badgeClass = '';
          let cell;
          if (cellData.type === TableCellType.Action) {
            cell = (
              <ActionCell
                key={+i}
                sx={(theme: BaseTheme) => cellStyle({ theme, cellData, type, hideLastBorder, index: i })}
                align={cellData.align.toLowerCase() as 'left' | 'right'}
              >
                <Stack direction="row" justifyContent="flex-end">
                  {cellData.buttons.map((button, j) => {
                    return (
                      <div key={j}>
                        {button.value === ReservedButtonValue.Warning && (
                          <IconButton
                            size="small"
                            sx={(theme) => ({
                              color: theme.palette.warning.main,
                              fontSize: '1.3rem',
                              height: theme.spacing(1.5),
                            })}
                          >
                            <MdWarning />
                          </IconButton>
                        )}
                        {button.value === ReservedButtonValue.Pending && (
                          <IconButton
                            size="small"
                            sx={(theme) => ({ color: theme.palette.warning.main, fontSize: '1.3rem' })}
                          >
                            <MdTimer />
                          </IconButton>
                        )}
                        {(button.value || button.actions[0].value) &&
                          button.value !== ReservedButtonValue.Warning &&
                          button.value !== ReservedButtonValue.Pending && (
                            <Box sx={(theme) => ({ position: 'relative', margin: theme.spacing(0, 0, 0, 1.5) })}>
                              <>
                                <Button
                                  size="small"
                                  variant={button.variant.toLowerCase() as 'text' | 'outlined' | 'contained'}
                                  color={button.color.toLowerCase() as 'primary' | 'secondary'}
                                  disabled={actionsLoading[i] || cellData.disabled}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    if (button.actions.length > 1) {
                                      setButtonActionsOpen(button);
                                      return;
                                    }
                                    const url = button.actions[0]?.url ?? '';
                                    const query = button.actions[0]?.query ?? '';
                                    setActionsIndex(i);
                                    handleSchemeOrQuery(url, query, { setFormDialog: setFormDialog });
                                  }}
                                >
                                  {button.value || button.actions[0].value}
                                </Button>
                                {actionsLoading[i] && (
                                  <CircularProgress
                                    size={24}
                                    color={button.color.toLowerCase() as 'primary' | 'secondary'}
                                    sx={(theme) => ({
                                      position: 'absolute',
                                      top: '50%',
                                      marginTop: theme.spacing(-1.5),
                                      marginLeft: theme.spacing(-4),
                                    })}
                                  />
                                )}
                              </>
                            </Box>
                          )}
                      </div>
                    );
                  })}
                </Stack>
              </ActionCell>
            );
          } else if (cellData.type === TableCellType.Input) {
            cell = (
              <Cell
                key={+i}
                sx={(theme: BaseTheme) => cellStyle({ theme, cellData, type, hideLastBorder, index: i })}
                align={cellData.align.toLowerCase() as 'left' | 'right'}
              >
                <TextField
                  variant={cellData.disabled ? 'standard' : 'outlined'}
                  margin="dense"
                  fullWidth
                  disabled={hasPending || cellData.disabled}
                  sx={(theme) => ({
                    '& div': {
                      background: cellData.disabled ? 'inherit' : theme.palette.common.white,
                    },
                    '& input': {
                      padding: theme.spacing(1, 1.75),
                    },
                    minWidth: 82,
                    maxWidth: 120,
                    margin: theme.spacing(0, 0.5),
                  })}
                  type="number"
                  label=""
                  value={cellData.value}
                  onChange={(event) => {
                    if (onFieldChange) onFieldChange(i, event.target.value);
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  InputProps={{
                    ...(cellData.disabled ? { disableUnderline: cellData.disabled } : {}),
                  }}
                  onWheel={(event) => event.target instanceof HTMLElement && event.target.blur()}
                />
              </Cell>
            );
          } else if (cellData.type === TableCellType.InputCurrency) {
            cell = (
              <Cell
                key={+i}
                sx={(theme: BaseTheme) => cellStyle({ theme, cellData, type, hideLastBorder, index: i })}
                align={cellData.align.toLowerCase() as 'left' | 'right'}
              >
                <TextField
                  variant={cellData.disabled ? 'standard' : 'outlined'}
                  disabled={hasPending || cellData.disabled}
                  margin="dense"
                  fullWidth
                  sx={(theme) => ({
                    '& div': {
                      background: cellData.disabled ? 'inherit' : theme.palette.common.white,
                    },
                    '& input': {
                      padding: theme.spacing(1, 1.75),
                    },
                    minWidth: 82,
                    maxWidth: 120,
                    margin: theme.spacing(0, 0.5),
                  })}
                  label=""
                  value={cellData.value}
                  onChange={(event) => {
                    if (onFieldChange) onFieldChange(i, event.target.value);
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  InputProps={{
                    inputComponent: NumberFormatRands as any,
                    ...(cellData.disabled ? { disableUnderline: cellData.disabled } : {}),
                  }}
                />
              </Cell>
            );
          } else if (cellData.type === TableCellType.Icon) {
            cell = (
              <Cell
                key={+i}
                sx={mergeSx((theme: BaseTheme) => cellStyle({ theme, cellData, type, hideLastBorder, index: i }), {
                  minWidth: 84,
                })}
                align={cellData.align.toLowerCase() as 'left' | 'right'}
              >
                <Stack
                  direction="row"
                  sx={(theme) => ({
                    justifyContent: cellData.align.toLowerCase() as 'left' | 'right',
                    paddingRight: theme.spacing(1),
                    [theme.breakpoints.up('md')]: {
                      paddingX: theme.spacing(1),
                    },
                  })}
                >
                  <ProductIcons value={cellData.image} cases={cellData.value} />
                </Stack>
              </Cell>
            );
          } else if (cellData.type === TableCellType.Chip) {
            cell = (
              <Cell
                key={+i}
                sx={mergeSx((theme: BaseTheme) => cellStyle({ theme, cellData, type, hideLastBorder, index: i }), {
                  minWidth: 84,
                })}
                align={cellData.align.toLowerCase() as 'left' | 'right'}
              >
                <Stack
                  direction="row"
                  sx={(theme) => ({
                    justifyContent: cellData.align.toLowerCase() as 'left' | 'right',
                    paddingRight: theme.spacing(1),
                    [theme.breakpoints.up('md')]: {
                      paddingX: theme.spacing(1),
                    },
                  })}
                >
                  <QualityChips chips={cellData.buttons} variant={QualityChipVariant.Stacked} />
                </Stack>
              </Cell>
            );
          } else if (isDispatchAndDocsRedesignEnabled && cellData.type === TableCellType.Image) {
            cell = (
              <Cell
                key={+i}
                sx={mergeSx((theme: BaseTheme) => cellStyle({ theme, cellData, type, hideLastBorder, index: i }), {
                  minWidth: 84,
                })}
                align={cellData.align.toLowerCase() as 'left' | 'right'}
              >
                <TableCellImage data={cellData} />
              </Cell>
            );
          } else if (cellData.type === TableCellType.InputSelect) {
            cell = (
              <Cell
                key={+i}
                sx={(theme: BaseTheme) => cellStyle({ theme, cellData, type, hideLastBorder, index: i })}
                align={cellData.align.toLowerCase() as 'left' | 'right'}
              >
                <FormControl
                  sx={(theme) => ({
                    '& div': {
                      background: theme.palette.common.white,
                    },
                    minWidth: 82,
                    maxWidth: 120,
                    margin: theme.spacing(0, 0.5),
                  })}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                >
                  <Select
                    native
                    displayEmpty
                    disabled={hasPending || cellData.disabled}
                    value={cellData.value}
                    onChange={(event) => {
                      if (onFieldChange) onFieldChange(i, event.target.value);
                    }}
                  >
                    <option value="0" aria-label="None">
                      None
                    </option>
                    {cellData?.options?.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Cell>
            );
          } else {
            cell = (
              <Cell
                key={+i}
                sx={mergeSx(
                  (theme: BaseTheme) =>
                    cellStyle({
                      theme,
                      cellData,
                      type,
                      hideLastBorder,
                      isNested,
                      shiftRight,
                      hideLeftBorder,
                      hideRightBorder,
                      isInline,
                      index: i,
                    }),
                  { '&.MuiTableCell-root': { ...(isReportingType && { padding: baseTheme.spacing(2, 1) }) } },
                )}
                align={cellData.align.toLowerCase() as 'left' | 'right'}
              >
                {i === 0 &&
                  isExpandable &&
                  (isStatisticalType || isReportingType ? (
                    <IconButton size="small" sx={{ padding: 0, marginRight: 1 }}>
                      {open ? <MdKeyboardArrowUp size={16} /> : <MdKeyboardArrowDown size={16} />}
                    </IconButton>
                  ) : (
                    <Hide>
                      <IconButton size="small" sx={{ padding: 0 }}>
                        {open ? <MdKeyboardArrowUp size={20} /> : <MdKeyboardArrowDown size={20} />}
                      </IconButton>
                    </Hide>
                  ))}
                {cellData.image !== '' && (
                  <Hide direction="up">
                    {/* TODO: Handle other icon types */}
                    <Box
                      sx={(theme) => ({ opacity: 0.6, marginTop: theme.spacing(-0.3), marginLeft: theme.spacing(-1) })}
                    >
                      <MdLocationOn />
                    </Box>
                  </Hide>
                )}
                <Hide direction="up">
                  {cellData.subtitle.length > 0 ? (
                    <span>
                      {cellData.value}
                      <br />
                      <Box
                        component="span"
                        sx={(theme) => ({
                          fontWeight: theme.typography.fontWeightRegular,
                          color: theme.palette.text.secondary,
                        })}
                      >
                        {cellData.subtitle}
                      </Box>
                    </span>
                  ) : (
                    <span className={badgeClass}>{cellData.value}</span>
                  )}
                </Hide>
                <Hide>
                  {cellData.subtitle.length > 0 ? (
                    <Box>
                      {cellData.value}
                      <Box
                        sx={(theme) => ({
                          fontWeight: theme.typography.fontWeightRegular,
                          color: theme.palette.text.secondary,
                        })}
                      >
                        {cellData.subtitle}
                      </Box>
                    </Box>
                  ) : (
                    <span className={badgeClass}>{cellData.value}</span>
                  )}
                </Hide>
                {isStatisticalType && cellData.tags.length > 0 && (
                  <Stack sx={(theme) => ({ display: 'flex', flexDirection: 'row', gap: theme.spacing(0.25) })}>
                    {cellData.tags.map((productTag, i) => {
                      if (productTag.label.length === 0) {
                        return null;
                      }
                      return (
                        <Chip
                          key={i}
                          label={productTag?.label}
                          sx={(theme) => ({
                            background: theme.palette.primary.light,
                            color: theme.palette.common.white,
                            fontSize: theme.typography.pxToRem(12),
                            width: 'max-content',
                            height: theme.spacing(2.5),
                          })}
                        />
                      );
                    })}
                  </Stack>
                )}
              </Cell>
            );
          }
          return cellData.mobile ? cell : isStatisticalType || isReportingType ? cell : <Hide key={+i}>{cell}</Hide>;
        })}
        {isStatisticalType && ( // Adds additional space after table columns for pricing dashboard sales table
          <Cell
            sx={(theme) => ({
              '&.MuiTableCell-root': {
                background: isNested ? theme.palette.common.white : theme.palette.tertiary.light,
                borderRight: 'hidden',
                maxWidth: theme.spacing(1),
                minWidth: theme.spacing(1),
              },
            })}
          />
        )}
        {showCheckbox && (
          <Cell>
            <Checkbox
              disabled={hasPending}
              color="primary"
              checked={checked}
              onChange={(event) => {
                if (onCheckboxToggle) onCheckboxToggle(event.target.checked);
              }}
              onClick={(event) => {
                event.stopPropagation();
              }}
            />
          </Cell>
        )}
      </TableRow>
      {open && isExpandable && (
        <>
          {data.dataType !== RowDataType.Rows && !isReportingType && (
            <TableRow>
              <TableCell
                sx={(theme) => ({
                  padding: theme.spacing(1),
                  borderBottom: 0,
                  overflow: 'hidden',
                  background: theme.palette.background.paper,
                })}
                colSpan={12} /* TODO: Set colSpan exactly */
              >
                <Collapse in={open} timeout="auto" unmountOnExit>
                  {data.dataType === RowDataType.Shipment && (
                    <ShipmentRowDetail
                      key={data.id}
                      data={data.data as Shipment}
                      refresh={refresh}
                      isFetching={isFetching}
                    />
                  )}
                  {data.dataType === RowDataType.SubTable && <SubTable data={data.data} onRefresh={refresh} />}
                </Collapse>
              </TableCell>
            </TableRow>
          )}
          {isPriceTableRowDetail && <PriceTableRowDetail data={data} setShowGraphs={setShowGraphs} />}
          {isReportTableRowDetail && (
            <TableRow>
              <TableCell
                sx={(theme) => ({
                  padding: theme.spacing(1),
                  borderBottom: 0,
                  overflow: 'hidden',
                  background: theme.palette.background.paper,
                })}
                colSpan={12} /* TODO: Set colSpan exactly */
              >
                <ReportTableRowDetail data={data.data} />
              </TableCell>
            </TableRow>
          )}
          {nestedData?.map((row, k) => (
            <Row
              key={k}
              isFetching={isFetching}
              hasPending={hasPending}
              data={row}
              isNested
              type={type}
              initExpanded={false}
              refresh={refresh}
              highlighted={false}
              onCustomSchemeRequest={onCustomSchemeRequest}
            />
          ))}
          {!isStatisticalType && data.dataType === RowDataType.Rows && (
            <EditHistoryRowDetail data={data} refresh={refresh} />
          )}
        </>
      )}
      {tableDialog.content && <TableDialog data={tableDialog.content} onClose={tableDialog.clear} />}
      <ErrorSnackbar isOpen={!!errorMessage} onClose={clearError}>
        {errorMessage!}
      </ErrorSnackbar>
      {buttonActionsOpen && (
        <ActionList
          open={!!buttonActionsOpen}
          button={buttonActionsOpen}
          onClose={(action?: Action) => {
            setButtonActionsOpen(undefined);
            if (!action) return;
            handleSchemeOrQuery(action.url, action.query, { setFormDialog: setFormDialog });
          }}
        />
      )}
      {paymentDetails.open && (
        <BankDepositDetailsDialog shipmentId={paymentDetails.id} onClose={paymentDetails.onClose} />
      )}
      {formDialog.open && (
        <LazyFormDialog
          type={formDialog.type}
          editIDs={formDialog.editIDs}
          open={formDialog.open}
          onClose={() => {
            setFormDialog(defaultFormDialogState);
          }}
          onSubmit={() => {
            setFormDialog(defaultFormDialogState);
            refresh();
          }}
        />
      )}
    </>
  );
};

const getNestedData = (index: number, rows: TableRowData[]) => {
  let nestedData = [];
  for (let i = index + 1; i < rows.length; i++) {
    if (rows[i].data === null) {
      break;
    }
    nestedData.push(rows[i]);
  }
  return nestedData;
};

export const Group = ({
  group,
  index,
  isFetching,
  hasPending,
  groups,
  columns,
  type,
  footer,
  actions,
  refresh,
  onFieldUpdate,
  onCustomSchemeRequest,
  setShowGraphs,
  setSelectedRows,
}: {
  type: TableType;
  groups: TableRowGroup[];
  group: TableRowGroup;
  index: number;
  actions: TableAction[];
  columns: TableColumn[];
  isFetching: boolean;
  hasPending: boolean;
  footer: ReactNode;
  refresh: () => void;
  onFieldUpdate(rowId: string, cellIndex: number, newValue: string): void;
  onCustomSchemeRequest(url: string): void;
  setShowGraphs(showGraph: boolean): void;
  setSelectedRows(selectedRow: string[]): void;
}) => {
  const isMobile = useIsViewport();
  const [selectedRowsDictionary, setSelectedRowsDictionary] = useState<{ [key: string]: any }>({});
  const showRowSelectionCheckboxes = actions.some((action) => action.minRequiredRowSelection >= 1);
  const isSingleRow = groups.length === 1 && groups[0].rows.length === 1;
  const isStatisticalType = type === TableType.Statistics;
  const isReportingType = type === TableType.Reporting;
  const hasTotals = group.totals && group.totals.length > 0;
  const hasMobileTotal = isMobile && group?.totals?.some((totalCell) => totalCell.mobile);
  const showValueRow = !isStatisticalType && hasTotals && (!isMobile || hasMobileTotal);
  const isExpandable = group.rows.length > 0;
  const [openGroup, setOpenGroup] = useState(true);

  const handleRowClick = (isExpandable: boolean) => {
    if (!isExpandable) {
      return;
    }
    setOpenGroup((value) => !value);
  };

  useEffect(() => {
    setSelectedRows(Object.keys(selectedRowsDictionary).filter((key) => selectedRowsDictionary[key]));
  }, [selectedRowsDictionary, setSelectedRows]);

  const handleCheckboxToggle = (rowId: string, value: boolean) => {
    setSelectedRowsDictionary((dict) => ({
      ...dict,
      [rowId]: value,
    }));
  };

  return (
    <TableBody key={+index}>
      {isStatisticalType && hasTotals && (
        <TableRow key={index} onClick={() => handleRowClick(isExpandable)}>
          {group?.totals?.map((total, j) => (
            <Cell
              key={j}
              align={total.align.toLowerCase() as 'left' | 'right'}
              sx={(theme) => ({
                '&.MuiTableCell-root': {
                  background: theme.palette.grey[50],
                  borderRight: j > 0 ? 'hidden' : '',
                  borderLeft: j === 0 ? 'hidden' : '',
                  paddingY: theme.spacing(1),
                  ...(j === 0 && stickyColumnStyle),
                },
              })}
            >
              <Stack
                sx={(theme) => ({
                  display: 'flex',
                  flexDirection: 'row',
                  gap: theme.spacing(1),
                  '&.MuiStack-root': {
                    justifyContent: (total.align.toLowerCase() as 'left' | 'right') ?? 'left',
                  },
                })}
              >
                {isExpandable && (
                  <IconButton size="small" sx={{ padding: 0 }}>
                    {openGroup ? <MdKeyboardArrowUp size={20} /> : <MdKeyboardArrowDown size={20} />}
                  </IconButton>
                )}
                <Typography
                  variant="body1"
                  sx={(theme) => ({
                    fontFamily: `${
                      total.fontType === FontType.Normal
                        ? theme.typography.fontFamily
                        : total.fontType === FontType.Mono
                          ? 'Roboto Mono'
                          : ''
                    }`,
                    fontWeight: `${total.fontStyle === FontStyle.Bold ? theme.typography.fontWeightBold : ''}`,
                  })}
                >
                  {total.value}
                </Typography>
              </Stack>
            </Cell>
          ))}
          <Cell
            sx={(theme) => ({
              '&.MuiTableCell-root': {
                background: theme.palette.grey[50],
                borderRight: 'hidden',
              },
            })}
            colSpan={columns.length - (group?.totals?.length ?? 0)}
          />
          <Cell // Adds additional space after table columns for pricing dashboard sales table
            sx={(theme) => ({
              '&.MuiTableCell-root': {
                background: theme.palette.grey[50],
                borderRight: 'hidden',
                maxWidth: theme.spacing(1),
                minWidth: theme.spacing(1),
              },
            })}
          />
        </TableRow>
      )}
      {isStatisticalType
        ? group.rows.map(
            (row, k) =>
              row.data === null && // TODO: Maybe add a boolean on BE instead of doing null check
              openGroup &&
              isExpandable && (
                <Row
                  key={k}
                  initExpanded
                  isFetching={isFetching}
                  hasPending={hasPending}
                  data={row}
                  nestedData={getNestedData(k, group.rows)}
                  type={type}
                  refresh={refresh}
                  setShowGraphs={setShowGraphs}
                />
              ),
          )
        : group.rows.map((row, k) => (
            <Row
              isFetching={isFetching}
              hasPending={hasPending}
              key={k}
              data={row}
              type={type}
              initExpanded={isSingleRow || isReportingType}
              refresh={refresh}
              hideLastBorder={!footer && index === group.rows.length - 1}
              highlighted={row.highlighted}
              showCheckbox={showRowSelectionCheckboxes}
              checked={selectedRowsDictionary[row.id]}
              onCheckboxToggle={(newValue) => handleCheckboxToggle(row.id, newValue)}
              onFieldChange={(cellIndex, newValue) => {
                onFieldUpdate(row.id, cellIndex, newValue);
              }}
              onCustomSchemeRequest={onCustomSchemeRequest}
            />
          ))}
      {showValueRow && (
        <TableRow>
          {group?.totals
            ?.filter((total) => !isMobile || total.mobile)
            .map((total, j) => (
              <Cell key={+j} align={total.align.toLowerCase() as 'left' | 'right'}>
                <Box
                  component="span"
                  sx={(theme) => ({
                    ...(total.fontType === FontType.Normal && { fontFamily: theme.typography.fontFamily }),
                    ...(total.fontType === FontType.Mono && { fontFamily: 'Roboto Mono' }),
                    ...(total.fontStyle === FontStyle.Bold && {
                      fontWeight: theme.typography.fontWeightMedium,
                    }),
                  })}
                >
                  {total.value}
                </Box>
              </Cell>
            ))}
        </TableRow>
      )}
    </TableBody>
  );
};

export interface TableRowData extends ExpandableTableRow {
  data?: any;
}

export interface TableRowGroup {
  rows: TableRowData[];
  total: string;
  totals?: TableCellData[];
}

export const ExpandableTablePlaceholder = () => {
  return (
    <TableContainer
      sx={(theme) => ({
        border: `1px solid ${theme.palette.tertiary?.main}`,
        borderRadius: theme.spacing(1),
        marginTop: theme.spacing(2),
      })}
    >
      <Table
        sx={(theme) => ({
          borderCollapse: 'separate',
          borderRadius: theme.spacing(1),
          [theme.breakpoints.down('md')]: {
            background: theme.palette.tertiary?.main,
          },
        })}
      >
        <Hide>
          <TableHead>
            <TableRow sx={(theme) => ({ overflow: 'none', background: theme.palette.background.paper })}>
              <TableCell sx={(theme) => ({ borderBottom: `1px solid ${theme.palette.common.white}` })} />
            </TableRow>
          </TableHead>
        </Hide>
        <TableBody>
          {Array.from({ length: 10 }).map((_, index) => (
            <TableRow
              key={index}
              sx={(theme) => ({
                background: theme.palette.tertiary?.light,
                borderBottom: `1px solid ${theme.palette.common.white}`,
                height: '50px',
              })}
            >
              <Cell />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

interface TableProps {
  title?: string;
  showTitle?: boolean;
  groups: TableRowGroup[];
  columns: TableColumn[];
  totals?: TableCellData[];
  type?: TableType;
  isFetching?: boolean;
  hasPending?: boolean;
  footer?: ReactNode;
  noBorder?: boolean;
  refresh?: () => void;
  onActionClick?(actionCode: string, rowIds: string[]): void;
  actions?: TableAction[];
  onFieldUpdate?(rowId: string, cellIndex: number, newValue: string): void;
  onCustomSchemeRequest?(url: string): void;
  setShowGraphs?(showGraph: boolean): void;
}

const ExpandableTable = ({
  title,
  showTitle = false,
  isFetching = false,
  hasPending = false,
  groups,
  columns,
  type = TableType.Standard,
  footer,
  totals,
  actions = [],
  noBorder = false,
  refresh = () => {},
  onActionClick = () => {},
  onFieldUpdate = () => {},
  onCustomSchemeRequest = () => {},
  setShowGraphs = () => {},
}: TableProps) => {
  const { isEnabled } = useFeatures();
  const isDispatchAndDocsRedesignEnabled = isEnabled('DispatchAndDocsRedesign');

  const theme = useTheme();
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const isMobile = useIsViewport();
  const showRowSelectionCheckboxes = actions.some((action) => action.minRequiredRowSelection >= 1);
  const isDetailType = type === TableType.Detail;
  const isStatisticalType = type === TableType.Statistics;
  const isReportingType = type === TableType.Reporting;
  const isBasicType = isDispatchAndDocsRedesignEnabled && type === TableType.Basic;
  const [formDialog, setFormDialog] = useState<FormDialogState>(defaultFormDialogState);
  const { handleSchemeOrQuery } = useHandleCustomSchemeAndQuery();
  const showHeaderColumns = (!(isMobile && !isStatisticalType) && !isReportingType) || isBasicType;

  const handleFormSubmit = () => {
    setFormDialog(defaultFormDialogState);
    refresh();
  };

  return (
    <>
      {isDispatchAndDocsRedesignEnabled ? (
        <Stack
          flexDirection={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={(theme) => ({
            [theme.breakpoints.down('md')]: {
              paddingX: theme.spacing(1),
            },
          })}
        >
          <Typography
            variant="body1"
            sx={(theme) => ({
              fontWeight: theme.typography.fontWeightBold,
            })}
          >
            {showTitle ? title : ''}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            {hasPending ? (
              <Button sx={{ margin: theme.spacing(-1.5, 2, 0) }}>
                Updates pending
                <CircularProgress
                  size={20}
                  color="secondary"
                  sx={{ marginLeft: 1.5, minWidth: '20px', minHeight: '20px' }}
                />
              </Button>
            ) : undefined}

            <TableActionBar
              type={type}
              actions={actions}
              disabled={hasPending}
              selectedRowIds={selectedRows}
              onActionClick={onActionClick}
              setFormDialog={setFormDialog}
            />
          </Box>
        </Stack>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          {hasPending ? (
            <Button sx={{ margin: theme.spacing(-1.5, 2, 0) }}>
              Updates pending
              <CircularProgress
                size={20}
                color="secondary"
                sx={{ marginLeft: 1.5, minWidth: '20px', minHeight: '20px' }}
              />
            </Button>
          ) : undefined}
          <TableActionBar
            actions={actions}
            disabled={hasPending}
            selectedRowIds={selectedRows}
            onActionClick={onActionClick}
          />
        </Box>
      )}
      <TableContainer
        sx={(theme) => ({
          '&::-webkit-scrollbar': {
            height: theme.spacing(1),
          },
          scrollbarWidth: theme.spacing(1),
          border:
            !isStatisticalType && !isReportingType
              ? `1px solid ${isDetailType ? theme.palette.grey[400] : theme.palette.tertiary?.main}`
              : 'none',
          borderRadius: theme.spacing(isStatisticalType ? 0 : 1),
          maxHeight: isStatisticalType ? 'calc(100vh - 76px - 48px - 105px)' : 'auto',
        })}
      >
        {isFetching && <LinearProgress color="secondary" />}
        <Table
          sx={(theme) => ({
            border: noBorder ? 'none' : '',
            ...(isDetailType
              ? { borderCollapse: 'separate', borderRadius: theme.spacing(1) }
              : isStatisticalType
                ? {
                    '& .MuiTableCell-root': {
                      border: `1px solid ${theme.palette.grey[300]}`,
                      backgroundColor: theme.palette.common.white,
                      textAlign: 'left',
                      padding: theme.spacing(1),
                    },
                    '& .MuiTableCell-head': {
                      fontWeight: theme.typography.fontWeightBold,
                      color: theme.palette.common.black,
                      fontSize: theme.spacing(1.5),
                    },
                  }
                : {
                    borderCollapse: 'separate',
                    borderRadius: theme.spacing(1),
                    [theme.breakpoints.down('md')]: {
                      background: theme.palette.tertiary?.main,
                      borderRadius: isBasicType ? 0 : theme.spacing(1),
                    },
                  }),
          })}
        >
          {showHeaderColumns && (
            <TableHead
              sx={{
                boxShadow: isBasicType ? '1px 0px 5px rgba(0,0,0,0.4)' : 'none',
                zIndex: isBasicType ? 5 : 0,
                position: isBasicType ? 'relative' : 'static',
              }}
            >
              <TableRow
                sx={(theme) => ({
                  overflow: 'none',
                  background: isBasicType ? theme.palette.tertiary.light : theme.palette.background.paper,
                })}
              >
                {columns.map((column, i) => (
                  <Cell
                    key={+i}
                    sx={(theme) => ({
                      verticalAlign: 'top',
                      padding: isBasicType ? theme.spacing(2, 1) : theme.spacing(0.5, 1.5, 0.25, 1.5),
                      color: isBasicType ? theme.palette.primary.main : theme.palette.text.secondary,
                      minWidth: 102,
                      borderBottom: `1px solid ${
                        type === TableType.Detail ? theme.palette.grey[300] : theme.palette.common.white
                      }`,
                      '&.MuiTableCell-root': {
                        ...(isStatisticalType
                          ? {
                              borderRight: i > 0 ? 'hidden' : '',
                              borderLeft: i === 0 ? 'hidden' : '',
                              borderTop: 'hidden',
                              minWidth: '120px',
                              width: '170px',
                              maxWidth: '200px',
                              paddingY: theme.spacing(2),
                              textAlign: isStatisticalType ? column.align : '',
                              ...(i === 0 && stickyColumnStyle),
                              ...stickyRowStyle(i),
                            }
                          : {}),
                      },
                    })}
                    align={column.align.toLowerCase() as 'left' | 'right'}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: column.align === 'Right' ? 'flex-end' : 'flex-start',
                      }}
                    >
                      <Box>{column.value}</Box>
                      {isStatisticalType && (
                        <>
                          {column.tags.length > 0 &&
                            column.tags.map((tag, i) => {
                              return (
                                <Box
                                  key={i}
                                  sx={(theme) => ({
                                    fontSize: theme.typography.pxToRem(12),
                                    width: 'max-content',
                                    fontWeight: theme.typography.fontWeightLight,
                                    display: 'table-cell',
                                    maxWidth: theme.spacing(12),
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    textAlign: column.align,
                                    '&:hover': {
                                      fontWeight: theme.typography.fontWeightBold,
                                    },
                                  })}
                                  onClick={() =>
                                    handleSchemeOrQuery(tag.actions[0].url, '', {
                                      setFormDialog: setFormDialog,
                                    })
                                  }
                                >
                                  {tag?.label ?? ''}
                                </Box>
                              );
                            })}
                        </>
                      )}
                    </Box>
                  </Cell>
                ))}
                {showRowSelectionCheckboxes && <TableCell width={theme.spacing(1)} />}
              </TableRow>
            </TableHead>
          )}
          {groups?.map((group, i) => {
            return (
              <Group
                key={i}
                groups={groups}
                group={group}
                index={i}
                isFetching
                hasPending={hasPending}
                columns={columns}
                type={type}
                footer={footer}
                actions={actions}
                refresh={refresh}
                onFieldUpdate={onFieldUpdate}
                onCustomSchemeRequest={onCustomSchemeRequest}
                setShowGraphs={setShowGraphs}
                setSelectedRows={setSelectedRows}
              />
            );
          })}
          {!!totals && !!totals.length && (!isMobile || totals.some((totalCell) => totalCell.mobile)) && (
            <TableRow sx={(theme) => ({ background: theme.palette.tertiary?.light })}>
              {totals
                .filter((total) => !isMobile || total.mobile)
                .map((total, i) => (
                  <Cell
                    key={+i}
                    align={total.align.toLowerCase() as 'left' | 'right'}
                    sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}
                  >
                    <Box
                      component="span"
                      sx={(theme) => ({
                        ...(total.fontType === FontType.Normal && { fontFamily: theme.typography.fontFamily }),
                        ...(total.fontType === FontType.Mono && { fontFamily: 'Roboto Mono' }),
                        ...(total.fontStyle === FontStyle.Bold && {
                          fontWeight: theme.typography.fontWeightMedium,
                        }),
                      })}
                    >
                      {total.value}
                    </Box>
                  </Cell>
                ))}
            </TableRow>
          )}
          <TableFooter>
            <TableRow sx={(theme) => ({ width: '100%', background: theme.palette.background.paper })}>
              {footer}
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <LazyFormDialog
        type={formDialog.type}
        editIDs={formDialog.editIDs}
        eTags={formDialog.eTags}
        open={formDialog.open}
        confirmDialogVariant={ConfirmDialogVariant.Default}
        onSubmit={handleFormSubmit}
        onClose={() => {
          setFormDialog(defaultFormDialogState);
        }}
      />
    </>
  );
};

export default ExpandableTable;
