import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router';
import { getRoutePrefix } from 'services/theming';
import { useFeatures } from 'hooks/useFeatures';
import useUser from 'hooks/useUser';
import { hasRobotToken } from 'utils/hasRobotToken';
import VerifyEmail from './VerifyEmail/VerifyEmail';

export const RequiresAuth = ({ allowRobot = false }: { allowRobot?: boolean }) => {
  const prefix = getRoutePrefix();

  const { user } = useUser();
  const navigate = useNavigate();
  const hasToken = hasRobotToken();
  const { isEnabled } = useFeatures();
  const isSecurityEnabled = isEnabled('Security');

  useEffect(() => {
    //Only navigate when user is not logged in and doesn't have a robot token
    if (!user && !hasToken) {
      if (isSecurityEnabled) {
        navigate(`${prefix}`, { replace: true });
      } else {
        navigate(`${prefix}/login`, { replace: true });
      }
    }
  }, [navigate, prefix, hasToken, user, isSecurityEnabled]);

  if (user && !hasToken && isSecurityEnabled) {
    return (
      <VerifyEmail>
        <Outlet />
      </VerifyEmail>
    );
  }
  if (user || (hasToken && allowRobot)) {
    return <Outlet />;
  }

  return null;
};
