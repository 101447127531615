import { Box, SxProps } from '@mui/material';
import { ThemeVariant, getThemeVariant } from 'services/theming';
import { BaseTheme } from 'config/base-theme';
import beans from './Beans.svg';
import fennel from './Fennel.svg';
import kale from './Kale.svg';
import pakchoi from './Pakchoi.svg';
import animalFeed from './animal-feed.svg';
import apple from './apple.svg';
import artichoke from './artichoke.svg';
import asparagus from './asparagus.svg';
import avocado from './avocado.svg';
import banana from './banana.svg';
import beetroot from './beetroot.svg';
import berries from './berries.svg';
import bringle from './bringle.svg';
import broccoli from './broccoli.svg';
import butternut from './butternut.svg';
import cabbage from './cabbage.svg';
import carrot from './carrot.svg';
import cauliflower from './cauliflower.svg';
import celery from './celery.svg';
import cherry from './cherry.svg';
import chilli from './chilli.svg';
import coconut from './coconut.svg';
import corn from './corn.svg';
import cucumber from './cucumber.svg';
import equipment from './equipment.svg';
import expertAdvice from './expert-advice.svg';
import fertilizer from './fertilizer.svg';
import fig from './fig.svg';
import fire from './fire.svg';
import flower from './flower.svg';
import garlic from './garlic.svg';
import generalGoods from './general-goods.svg';
import ginger from './ginger.svg';
import goggles from './goggles.svg';
import gooseberry from './gooseberry.svg';
import grapes from './grapes.svg';
import greenbean from './greenbean.svg';
import greenhouse from './greenhouse.svg';
import guava from './guava.svg';
import herb from './herb.svg';
import irrigation from './irrigation.svg';
import kiwi from './kiwi.svg';
import leeks from './leek.svg';
import lemon from './lemon.svg';
import lime from './lime.svg';
import litchi from './litchi.svg';
import livestock from './livestock.svg';
import mango from './mango.svg';
import mushroom from './mushroom.svg';
import muskmelon from './muskmelon.svg';
import onion from './onion.svg';
import orange from './orange.svg';
import packagingMaterial from './packaging-material.svg';
import papaya from './papaya.svg';
import passionFruit from './passion_fruit.svg';
import peach from './peach.svg';
import pear from './pear.svg';
import pepper from './pepper.svg';
import persimmon from './persimmons.svg';
import pillowPack from './pillow-pack.svg';
import pineapple from './pineapple.svg';
import plantNutrition from './plant-nutrition.svg';
import plum from './plum.svg';
import pomegranate from './pomegranate.svg';
import potato from './potato.svg';
import powerProducts from './power-products.svg';
import pumpkin from './pumpkin.svg';
import radish from './radish.svg';
import raspberry from './raspberry.svg';
import seed from './seed.svg';
import rice from './sheaf-of-rice.svg';
import shoe from './shoe.svg';
import soilAnalysis from './soil-analysis.svg';
import soup from './soup.svg';
import spinach from './spinach.svg';
import strawberry from './strawberry.svg';
import tomato from './tomato.svg';
import turnipIcon from './turnip-icon.svg';
import valueAdded from './value-added.svg';
import watermelon from './watermelon.svg';
import welder from './welder.svg';
import workwear from './workwear.svg';

export const getIconSrc = (sku: string) => {
  const theme = getThemeVariant();
  // TODO: Removed villa theme check for demo. Re-add when required by villa launch and icons reviewed
  if ([ThemeVariant.Inputs].includes(theme)) {
    if (sku.includes('soil')) return soilAnalysis;
    if (sku.includes('power products')) return powerProducts;
    if (sku.includes('plant')) return plantNutrition;
    if (sku.includes('greenhouse')) return generalGoods;
    if (sku.includes('fertilisers')) return fertilizer;
    if (sku.includes('fire')) return fire;
    if (sku.includes('packaging')) return packagingMaterial;
    if (sku.includes('growth')) return greenhouse;
    if (sku.includes('goggles')) return goggles;
    if (sku.includes('equipment')) return equipment;
    if (sku.includes('expert')) return expertAdvice;
    if (sku.includes('ppe') && !sku.includes('pepper')) return workwear;
    if (sku.includes('irrigation')) return irrigation;
    if (sku.includes('animal')) return animalFeed;
    if (sku.includes('weld')) return welder;
    if (sku.includes('seed')) return seed;
    if (sku.includes('livestock')) return livestock;
  }

  if ([ThemeVariant.Nile, ThemeVariant.FreshMark].includes(theme)) {
    if (sku.includes('artichoke')) return artichoke;
    if (sku.includes('pineap')) return pineapple;
    if (sku.includes('apple')) return apple;
    if (sku.includes('gooseberries')) return gooseberry;
    if (sku.includes('raspberries')) return raspberry;
    if (sku.includes('aspar') || sku.includes('bunches')) return asparagus;
    if (sku.includes('avoc')) return avocado;
    if (sku.includes('marrow')) return cucumber;
    if (sku.includes('bana')) return banana;
    if (sku.includes('beans')) return beans;
    if (sku.includes('beetr')) return beetroot;
    if (sku.includes('strawb')) return strawberry;
    if (sku.includes('berr')) return berries;
    if (sku.includes('brin')) return bringle;
    if (sku.includes('broc')) return broccoli;
    if (sku.includes('butt') && !sku.includes('mushr')) return butternut;
    if (sku.includes('cabba')) return cabbage;
    if (sku.includes('carrot')) return carrot;
    if (sku.includes('cauli')) return cauliflower;
    if (sku.includes('cherr') && !sku.includes('tomat')) return cherry;
    if (sku.includes('chilli')) return chilli;
    if (sku.includes('citr')) return orange;
    if (sku.includes('coconut')) return coconut;
    if (sku.includes('corn')) return corn;
    if (sku.includes('cucum')) return cucumber;
    if (sku.includes('fennel')) return fennel;
    if (sku.includes('fig')) return fig;
    if (sku.includes('garlic')) return garlic;
    if (sku.includes('ginger')) return ginger;
    if (sku.includes('grapefruit')) return orange;
    if (sku.includes('grape') || sku.includes('blueberr')) return grapes;
    if (sku.includes('green') && !sku.includes('pepper') && !sku.includes('mango')) return greenbean;
    if (sku.includes('guava')) return guava;
    if (sku.includes('kiwi')) return kiwi;
    if (sku.includes('kale')) return kale;
    if (sku.includes('lettuce')) return cabbage;
    if (sku.includes('leek')) return leeks;
    if (sku.includes('turnip')) return turnipIcon;
    if (sku.includes('lemon')) return lemon;
    if (sku.includes('mango') || sku.includes('apricot') || sku.includes('mantom')) return mango;
    if (sku.includes('mushr')) return mushroom;
    if (sku.includes('melon') && !sku.includes('water')) return muskmelon;
    if (sku.includes('onio')) return onion;
    if (sku.includes('orang') && !sku.includes('potato')) return orange;
    if (sku.includes('pak')) return pakchoi;
    if (sku.includes('papa')) return papaya;
    if (sku.includes('pear') && !sku.includes('pot')) return pear;
    if (sku.includes('peach') || sku.includes('necta')) return peach;
    if (sku.includes('pepper')) return pepper;
    if (sku.includes('persimmon')) return persimmon;
    if (sku.includes('pillow')) return pillowPack;
    if (sku.includes('plum') || sku.includes('stone') || sku.includes('prune')) return plum;
    if (sku.includes('potato') || sku.includes('pototh') || sku.includes('potmon')) return potato;
    if (sku.includes('pomegranate')) return pomegranate;
    if (sku.includes('pumpk')) return pumpkin;
    if (sku.includes('radish')) return radish;
    if (sku.includes('rice')) return rice;
    if (sku.includes('show')) return shoe;
    if (sku.includes('spinac')) return spinach;
    if (sku.includes('soup')) return soup;
    if (sku.includes('squash') || sku.includes('hubbard')) return pumpkin;
    if (sku.includes('tomato')) return tomato;
    if (sku.includes('waterme')) return watermelon;
    if (sku.includes('litchi')) return litchi;
    if (sku.includes('celery')) return celery;
    if (sku.includes('lime')) return lime;
    if (sku.includes('flower') || sku.includes('rose')) return flower;
    if (sku.includes('herb')) return herb;
    if (sku.includes('passion')) return passionFruit;
    if (sku.includes('veg')) return fennel;
    if (['value added', 'sliced & diced', 'juice', 'shots', 'tubs'].some((current) => sku.includes(current)))
      return valueAdded;
  }

  return null;
};

interface ProductIconProps {
  sku: string;
  className?: string;
  sx?: SxProps<BaseTheme>;
  size?: number;
}

const ProductIcon = ({ sku, sx = {}, className = '', size = 30 }: ProductIconProps) => {
  const src = getIconSrc(sku?.toLowerCase() ?? '');
  if (!src) {
    return null;
  }
  return (
    <Box
      component="img"
      src={src}
      sx={{
        position: 'relative',
        width: size,
        height: size,
        padding: (theme) => theme.spacing(0.25),
        ...sx,
      }}
      className={className}
      alt=""
    />
  );
};

export default ProductIcon;
