import { Dialog } from '@mui/material';
import { useState } from 'react';
import ImageUploadActiveImage from './ImageUploadActiveImage';
import ImageUploadDialogContent from './ImageUploadDialogContent';
import ImageUploadDialogTitle from './ImageUploadDialogTitle';

const ImageUploadDialog = ({
  files = [],
  open,
  compressedFile,
  loading,
  onRemove,
  onClose,
  onFileUpload,
  onUploadComplete,
}: {
  files?: string[];
  open: boolean;
  compressedFile: File | null;
  loading: boolean;
  onRemove: (index: number) => void;
  onClose: () => void;
  onFileUpload: (event: any) => Promise<void>;
  onUploadComplete: (url: string) => void;
}) => {
  const [activeImage, setActiveImage] = useState('');

  return (
    <Dialog
      open={open}
      sx={(theme) => ({
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            [theme.breakpoints.up('md')]: {
              width: '100%',
              maxWidth: '450px',
            },
          },
        },
      })}
    >
      {activeImage.length > 0 ? (
        <ImageUploadActiveImage activeImage={activeImage} setActiveImage={setActiveImage} />
      ) : (
        <>
          <ImageUploadDialogTitle
            files={files}
            isEditor={Boolean(files && files?.length > 0)}
            loading={loading}
            onFileUpload={onFileUpload}
            onClose={onClose}
          />
          <ImageUploadDialogContent
            files={files}
            setActiveImage={setActiveImage}
            loading={loading}
            compressedFile={compressedFile}
            onUploadComplete={onUploadComplete}
            onRemove={onRemove}
          />
        </>
      )}
    </Dialog>
  );
};

export default ImageUploadDialog;
