import { Dialog, DialogTitle, DialogContent, List, ListItemText, Collapse, ListItemButton, Box } from '@mui/material';
import { useState } from 'react';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { getPrefixedRoute } from 'services/theming';
import { BaseTheme } from 'config/base-theme';
import isCustomScheme from 'utils/is-custom-scheme';
import { Action, Button } from 'generated/graphql';

const ActionItem = ({
  action,
  onClick,
  nested = false,
}: {
  action: Action;
  onClick: (action?: Action) => void;
  nested?: boolean;
}) => {
  const style = (theme: BaseTheme) =>
    nested
      ? {
          paddingLeft: theme.spacing(0.75),
        }
      : {};

  const isLink = action.url.length > 0 && !isCustomScheme(action.url);

  if (isLink) {
    const isExternal = action.url.startsWith('http');
    if (isExternal) {
      return (
        <ListItemButton component="a" href={action.url} target="_blank" onClick={() => onClick()}>
          <ListItemText disableTypography={nested} sx={style} primary={action.value} secondary={action.description} />
        </ListItemButton>
      );
    }
    const to = getPrefixedRoute(action.url);
    return (
      <ListItemButton
        component={Link}
        to={to.startsWith('#') ? to.slice(1) : to.replace('/#/', '')}
        onClick={() => onClick()}
      >
        <ListItemText disableTypography={nested} sx={style} primary={action.value} secondary={action.description} />
      </ListItemButton>
    );
  }

  return (
    <ListItemButton onClick={() => onClick(action)}>
      <ListItemText disableTypography={nested} sx={style} primary={action.value} secondary={action.description} />
    </ListItemButton>
  );
};

const ActionGroup = ({
  action,
  onClick,
  nested = false,
}: {
  action: Action;
  nested?: boolean;
  onClick: (action?: Action) => void;
}) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(!open);

  if (!action.group || action.group.length === 0) {
    return <ActionItem nested={nested} onClick={onClick} action={action} />;
  }

  return (
    <Box sx={(theme) => ({ paddingLeft: nested ? theme.spacing(0.75) : 0 })}>
      <ListItemButton onClick={handleClick}>
        <ListItemText primary={action.value} />
        {open ? <MdExpandLess size={20} /> : <MdExpandMore size={20} />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {action.group.map((action, i) => {
            if (!action) return null;
            return <ActionGroup nested key={`${action.value + i}`} onClick={onClick} action={action} />;
          })}
        </List>
      </Collapse>
    </Box>
  );
};

const ActionList = ({
  open,
  button,
  onClose,
}: {
  open: boolean;
  button: Button;
  onClose: (action?: Action) => void;
}) => {
  const handleClose = () => {
    onClose();
  };

  const handleSelect = (action?: Action) => {
    onClose(action);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{button.value}</DialogTitle>
      <DialogContent>
        <List>
          {button.actions.map((action, i) => {
            return <ActionGroup key={`${action.value + i}`} onClick={handleSelect} action={action} />;
          })}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default ActionList;
