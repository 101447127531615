import { Box, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import BtnIcon from 'components/BtnIcon';
import Button from 'components/Button';
import { BaseTheme } from 'config/base-theme';
import { useFeatures } from 'hooks/useFeatures';
import useHandleCustomSchemeAndQuery from 'hooks/useHandleCustomSchemeAndQuery';
import { SubTable as SubTableType, TableCellType } from 'generated/graphql';
import RowDetailItem from './RowDetailItem';

const boxStyle = (theme: BaseTheme) => ({
  borderCollapse: 'separate',
  padding: theme.spacing(1.2, 1.5, 0),
  background: theme.palette.grey[100],
  borderRadius: theme.spacing(1),
});

const SubTable = ({ data, onRefresh = () => {} }: { data: SubTableType; onRefresh?: () => void }) => {
  const hasItems = data.items.length > 0;
  const { isEnabled } = useFeatures();
  const isAdminPropertiesEnabled = isEnabled('AdminProperties');

  const { handleSchemeOrQuery } = useHandleCustomSchemeAndQuery(onRefresh);
  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={hasItems ? 7 : 12}>
        <Table sx={(theme) => ({ backgroundColor: theme.palette.grey[100], borderRadius: theme.spacing(1) })}>
          <TableHead>
            <TableRow>
              {data?.columns?.map((col) => (
                <TableCell
                  key={col.value}
                  align={col?.align.toLowerCase() as 'left' | 'right'}
                  sx={(theme) => ({ paddingY: theme.spacing(1) })}
                >
                  {col.value}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.rows?.map((row, index) => (
              <TableRow key={index}>
                {row?.map((cell) =>
                  isAdminPropertiesEnabled && cell.type === TableCellType.Action ? (
                    <TableCell
                      sx={{ border: index === data.rows.length - 1 ? 'none' : 'auto' }}
                      key={cell.value}
                      align={cell?.align.toLowerCase() as 'left' | 'right'}
                    >
                      {cell.buttons.map((button, j) => {
                        return (
                          <div key={j}>
                            {button.value || button.actions[0].value ? (
                              <Box sx={(theme) => ({ position: 'relative', margin: theme.spacing(0, 0, 0, 1.5) })}>
                                <Button
                                  size="small"
                                  color={button.color.toLowerCase() as 'primary' | 'secondary'}
                                  startIcon={<BtnIcon icon={button.icon} />}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    const url = button.actions[0]?.url ?? '';
                                    const query = button.actions[0]?.query ?? '';
                                    handleSchemeOrQuery(url, query);
                                  }}
                                >
                                  {button.value ?? button.actions[0].value}
                                </Button>
                              </Box>
                            ) : (
                              <Box sx={(theme) => ({ position: 'relative', margin: theme.spacing(0, 0, 0, 1.5) })}>
                                <IconButton
                                  size="small"
                                  color={button.color.toLowerCase() as 'primary' | 'secondary'}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    const url = button.actions[0]?.url ?? '';
                                    const query = button.actions[0]?.query ?? '';
                                    handleSchemeOrQuery(url, query);
                                  }}
                                >
                                  <BtnIcon icon={button.icon} />
                                </IconButton>
                              </Box>
                            )}
                          </div>
                        );
                      })}
                    </TableCell>
                  ) : (
                    <TableCell
                      sx={{ border: index === data.rows.length - 1 ? 'none' : 'auto' }}
                      key={cell.value}
                      align={cell?.align.toLowerCase() as 'left' | 'right'}
                    >
                      {cell.value}
                    </TableCell>
                  ),
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
      {hasItems && (
        <Grid item xs={12} md={5}>
          <Box
            sx={(theme) => ({
              ...boxStyle(theme),
              [theme.breakpoints.down('md')]: {
                margin: theme.spacing(-6, 0, 0),
              },
            })}
          >
            {data.items.map((item, i) => (
              <RowDetailItem data={item} key={`${item.info.value + i}`} />
            ))}
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default SubTable;
