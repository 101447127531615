import { RemoveType, useRemoveMutation, RemoveInputNew } from 'generated/graphql';

const useRemove = () => {
  const [, removeMutation] = useRemoveMutation();

  const handleRemove = async (url: string) => {
    const urlEntity = new URL(url);
    const type = urlEntity.searchParams.get('type') || '';
    const relatedEntityID = urlEntity.searchParams.get('relatedEntintyID') || '';
    let editIDs: string[] = [];
    try {
      const id = urlEntity.searchParams.get('ids') || '';
      if (id) {
        editIDs = JSON.parse(id) as string[];
      }
    } catch (e) {
      editIDs = [];
    }
    const input: RemoveInputNew = {
      type: type as RemoveType,
      uuids: editIDs,
      relatedEntityID,
    };
    const result = await removeMutation({
      input,
    });

    return result.error;
  };

  return handleRemove;
};

export default useRemove;
