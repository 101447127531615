import { CUSTOM_SCHEME_PATH } from 'services/api-constants';
import { useFeatures } from './useFeatures';
import useRemove from './useRemove';
import { useSwitchProfile } from './useSwitchProfile';
import useUser from './useUser';

/** Use handle method hook */
const useHandleMethod = () => {
  const { isEnabled } = useFeatures();
  const isAdminPropertiesEnabled = isEnabled('AdminProperties');

  const onSwitchProfile = useSwitchProfile();
  const { onLogout, user } = useUser();
  const onRemove = useRemove();

  const handleMethod = async (
    urlEntity: URL,
    setIsLoading: (value: boolean) => void,
    setErrorMessage: (value: string) => void,
    onRefresh?: () => void,
  ) => {
    if (urlEntity.pathname.startsWith(CUSTOM_SCHEME_PATH.Logout)) {
      await onLogout();
    } else if (urlEntity.pathname.startsWith(CUSTOM_SCHEME_PATH.SwitchProfile)) {
      const selectedId = urlEntity.searchParams.get('id') || '';
      if (user?.profileId?.toString() === selectedId.toString()) {
        return;
      }
      setIsLoading(true);
      const error = await onSwitchProfile(selectedId);
      if (error) {
        setErrorMessage(error.message);
        setIsLoading(false);
        return;
      }
      window.location.reload();
    } else if (isAdminPropertiesEnabled && urlEntity.pathname.startsWith(CUSTOM_SCHEME_PATH.Remove)) {
      setIsLoading(true);
      const error = await onRemove(urlEntity.href);
      if (error) {
        setErrorMessage(error.message);
        setIsLoading(false);
        return;
      }
      if (onRefresh) onRefresh();
      return;
    }
  };

  return handleMethod;
};

export default useHandleMethod;
