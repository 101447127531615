import { Alert, Box, CircularProgress, Typography } from '@mui/material';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { useEffect, useState } from 'react';
import { storage } from 'services/firebase';
import { v4 as uuidv4 } from 'uuid';

export const ImageUploadLoader = ({
  src = '',
  rawFile,
  small = false,
  onComplete,
}: {
  src?: string;
  rawFile: File;
  small?: boolean; // Makes loader small and hides the progress text
  onComplete(value: string): void;
}) => {
  const [progress, setProgress] = useState<null | number>(null);
  const [msg, setMsg] = useState('');
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    const handleUpload = async () => {
      try {
        setProgress(0);
        setUploading(true);
        const extension = rawFile.name.split('.').pop();
        const storageRef = ref(storage, `images/${uuidv4()}.${extension}`);
        const uploadTask = uploadBytesResumable(storageRef, rawFile, {
          customMetadata: {
            originalName: rawFile.name ?? '',
            extension: extension ?? '',
          },
        });

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            setProgress(Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100));
          },
          (error) => {
            switch (error.code) {
              case 'storage/unauthorized':
                setMsg('Uploading: unauthorized');
                break;
              case 'storage/canceled':
                setMsg('Uploading: canceled');
                break;
              case 'storage/unknown':
              default:
                setMsg('Failed to upload file');
                break;
            }
            setProgress(null);
          },
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            onComplete(downloadURL);
            setUploading(false);
            setMsg('');
          },
        );
      } catch (error) {
        setMsg('Failed to upload file');
        setUploading(false);
      }
    };
    if (rawFile) handleUpload();
  }, [rawFile, onComplete]);

  if (uploading || !src) {
    return (
      <Box
        sx={(theme) => ({ margin: theme.spacing(1, 0), width: small ? 30 : 'initial', height: small ? 30 : 'initial' })}
        position="relative"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {msg && <Alert severity="error">{msg}</Alert>}
        {progress !== null && (
          <>
            <CircularProgress
              variant={small ? 'indeterminate' : 'determinate'}
              value={progress}
              size={small ? 16 : 48}
            />
            {!small && (
              <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="caption" component="div" color="textSecondary">{`${progress}%`}</Typography>
              </Box>
            )}
          </>
        )}
      </Box>
    );
  }
};

export default ImageUploadLoader;
