import { Box, Stack, Typography } from '@mui/material';
import useIsViewport from 'hooks/useIsViewport';
import { TableCell } from 'generated/graphql';
import ProductIcon from './ProductIcon/ProductIcon';

const IMAGE_SIZE = 70;

const TableCellImage = ({ data }: { data: TableCell | undefined }) => {
  const isMobile = useIsViewport();
  if (!data) return null;
  return (
    <Stack direction={'row'} alignItems={'center'} gap={1}>
      {!isMobile && (
        <Stack
          sx={(theme) => ({
            background: theme.palette.grey[100],
            justifyContent: 'center',
            alignItems: 'center',
            minWidth: IMAGE_SIZE,
            maxWidth: IMAGE_SIZE,
            height: IMAGE_SIZE,
          })}
        >
          {data.image ? (
            <Box
              component="img"
              src={data.image}
              alt="Product image"
              sx={(theme) => ({
                objectFit: 'cover',
                borderRadius: theme.spacing(1),
              })}
            />
          ) : (
            <ProductIcon sku={data.value} />
          )}
        </Stack>
      )}
      {data.value && ( // Subtitles should only show when value/title is sent
        <Stack>
          <Typography
            sx={(theme) => ({
              [theme.breakpoints.down('md')]: {
                fontWeight: theme.typography.fontWeightMedium,
              },
            })}
          >
            {data.value}
          </Typography>
          {data.subtitle && (
            <Typography variant="body1" sx={(theme) => ({ color: theme.palette.grey[700] })}>
              {data.subtitle.split(';').map((line, index) => (
                <Box key={`${line}${index}`}>
                  {line}
                  <br />
                </Box>
              ))}
            </Typography>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default TableCellImage;
