import { Card, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { ThemeVariant, getThemeVariant } from 'services/theming';
import MobileHeader from 'components/MobileHeader';
import InputsBackground from './background-clouds-inputs.svg';
import Background from './background-clouds.svg';

const ScreenCard = ({
  title = '',
  stepper,
  subTitle = '',
  children,
  alignText = 'left',
  noPadding = false,
  hasMobileHeader = false,
}: {
  title?: string;
  stepper?: ReactNode;
  subTitle?: string;
  children: any;
  alignText?: string;
  noPadding?: boolean;
  hasMobileHeader?: boolean;
}) => {
  const themeVariant = getThemeVariant();
  return (
    <>
      {hasMobileHeader && <MobileHeader title={title} hasBackButton={false} noMargin />}
      <Card
        sx={(theme) => ({
          width: '100%',
          maxWidth: 500,
          margin: theme.spacing(10, 0),
          padding: noPadding ? 0 : theme.spacing(4, 3),
          paddingTop: noPadding ? 0 : theme.spacing(4),
          backgroundImage:
            ThemeVariant.Nile === themeVariant
              ? `url(${Background})`
              : ThemeVariant.Inputs
                ? `url(${InputsBackground})`
                : 'none',
          backgroundSize: 'cover',
          [theme.breakpoints.down('md')]: {
            minWidth: '100vw',
            padding: noPadding ? 0 : theme.spacing(4, 2),
            minHeight: hasMobileHeader ? 'calc(100vh - 56px)' : '100vh',
            margin: 0,
            borderRadius: 0,
          },
        })}
      >
        {stepper}
        {title && (
          <Typography sx={{ textAlign: alignText }} variant="h3" color="primary">
            {title}
          </Typography>
        )}
        {subTitle && (
          <Typography
            sx={(theme) => ({ textAlign: alignText, marginTop: theme.spacing(1) })}
            variant="subtitle1"
            color="textSecondary"
          >
            {subTitle}
          </Typography>
        )}
        {children}
      </Card>
    </>
  );
};

export default ScreenCard;
