import { create } from 'zustand';
import { MarketInput } from 'generated/graphql';

const useBuyersMarketStateStore = create<{
  input: MarketInput | undefined;
  setInput: (newInput: Partial<MarketInput>) => void;
}>((set) => ({
  input: undefined,
  setInput: (input: Partial<MarketInput>) =>
    set((store) => ({
      ...store,
      input: {
        ...store.input,
        ...input,
      } as MarketInput,
    })),
}));

export default useBuyersMarketStateStore;
