import { Button, Stack } from '@mui/material';
import { ElementRef, useCallback, useRef, useState } from 'react';
import { MdFileUpload } from 'react-icons/md';
import useFileCompression from 'hooks/useFileCompression';
import ImageUploadDialog from './ImageUploadDialog';
import ImageUploadLoader from './ImageUploadLoader';

const DEFAULT_FIELD_SIZE = '140px';
const IMAGE_SIZE = '30px';

const ACCEPTED_FILE_TYPES = 'image/*, application/pdf';

export interface FileMetaData {
  name?: string;
  size?: string;
}

const ImageUploadField = ({
  label = '',
  hasWhiteFields = false,
  files = [],
  onChange,
}: {
  label?: string;
  hasWhiteFields?: boolean;
  files?: string[];
  onChange: (urls: string[]) => void;
}) => {
  const hasUploadFile = files && files?.length > 0;
  const [open, setOpen] = useState(false);
  const [compressedFile, compressFile] = useFileCompression();
  const [loading, setLoading] = useState(false);
  const inputRef = useRef<ElementRef<'input'>>(null);

  const handleUploadClick = () => {
    if (!inputRef.current) {
      return;
    }
    inputRef.current.click();
  };

  const handleFileUpload = async (event: any) => {
    if (!event.target.files || !event.target.files.length) {
      compressFile(null);
      return;
    }
    setLoading(true);
    compressFile(event.target.files[0]);
  };

  const handleUploadComplete = useCallback(
    (url: string) => {
      setLoading(false);
      onChange([...files, url]);
      compressFile(null);
    },
    [files, onChange, compressFile],
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRemove = (index: number) => {
    onChange([...files.filter((_, srcIndex) => srcIndex !== index)]);
  };

  return (
    <>
      <input
        accept={ACCEPTED_FILE_TYPES}
        type="file"
        onChange={handleFileUpload}
        style={{ display: 'none' }}
        ref={inputRef}
      />
      <Button
        color="primary"
        variant={'outlined'}
        disabled={loading}
        sx={(theme) => ({
          width: DEFAULT_FIELD_SIZE,
          height: 40,
          padding: hasUploadFile ? 0 : theme.spacing(1),
          background: hasWhiteFields ? theme.palette.background.paper : 'initial',
        })}
        onClick={hasUploadFile ? handleOpen : handleUploadClick}
      >
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          sx={(theme) => ({
            paddingX: hasUploadFile ? theme.spacing(1) : 0,
            width: '100%',
          })}
        >
          {label}
          {loading && compressedFile ? (
            <ImageUploadLoader small rawFile={compressedFile} onComplete={handleUploadComplete} />
          ) : hasUploadFile ? (
            <img src={files[0]} alt="" width={IMAGE_SIZE} height={IMAGE_SIZE} style={{ borderRadius: 8 }} />
          ) : (
            <Stack width={IMAGE_SIZE} height={IMAGE_SIZE} alignItems={'center'} justifyContent={'center'}>
              <MdFileUpload size={16} />
            </Stack>
          )}
        </Stack>
      </Button>
      <ImageUploadDialog
        files={files}
        open={open}
        loading={loading}
        compressedFile={compressedFile}
        onRemove={handleRemove}
        onClose={handleClose}
        onFileUpload={handleFileUpload}
        onUploadComplete={handleUploadComplete}
      />
    </>
  );
};

export default ImageUploadField;
