import { Box, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { getMetadata, ref } from 'firebase/storage';
import prettyBytes from 'pretty-bytes';
import { useMemo, useState } from 'react';
import { FaEye } from 'react-icons/fa';
import { storage } from 'services/firebase';
import BtnIcon from 'components/BtnIcon';
import { ButtonIcon } from 'generated/graphql';

export const ImageUploadPreview = ({
  fileSRC,
  onRemove,
  onClick,
}: {
  fileSRC: string;
  onRemove: (url: string) => void;
  onClick: (src: string) => void;
}) => {
  const [name, setName] = useState('');
  const [size, setSize] = useState(0);
  const [loading, setLoading] = useState(true);

  const storageRef = ref(storage, fileSRC);
  useMemo(async () => {
    return await getMetadata(storageRef)
      .then((metadata) => {
        setName(metadata?.customMetadata?.originalName ?? '');
        setSize(metadata.size);
      })
      .catch(() => {
        setName('');
        setSize(0);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [storageRef]);

  if (!fileSRC || fileSRC?.length === 0) return null;

  return (
    <Stack
      direction={'row'}
      gap={1}
      justifyContent={'space-between'}
      alignItems={'center'}
      sx={(theme) => ({
        marginTop: theme.spacing(1),
      })}
    >
      <Stack direction={'row'} gap={1} sx={{ cursor: 'pointer' }} onClick={() => onClick(fileSRC)}>
        <Box
          component="img"
          src={fileSRC}
          loading="eager"
          sx={(theme) => ({
            minWidth: theme.spacing(8),
            maxWidth: theme.spacing(8),
            height: theme.spacing(8),
            objectFit: 'cover',
            borderRadius: theme.spacing(1),
            opacity: 0.3,
          })}
        />
        <Stack
          justifyContent={'center'}
          alignItems={'center'}
          sx={(theme) => ({
            minWidth: theme.spacing(8),
            maxWidth: theme.spacing(8),
            height: theme.spacing(8),
            marginLeft: theme.spacing(-9),
            borderRadius: theme.spacing(1),
          })}
        >
          <FaEye />
        </Stack>
        <Stack gap={loading ? 1 : 0} sx={{ justifyContent: 'center' }}>
          {loading ? <Skeleton variant="rectangular" width="100px" height="15px" /> : <Typography>{name}</Typography>}
          {loading ? (
            <Skeleton variant="rectangular" width="100px" height="15px" />
          ) : (
            <Typography variant={'body2'} sx={(theme) => ({ color: theme.palette.grey[700] })}>
              {prettyBytes(size)}
            </Typography>
          )}
        </Stack>
      </Stack>
      <IconButton size="medium" sx={{ height: 'fit-content' }} onClick={() => onRemove(fileSRC)}>
        <BtnIcon icon={ButtonIcon.Trash} />
      </IconButton>
    </Stack>
  );
};

export default ImageUploadPreview;
