import { StrictMode, useEffect } from 'react';
import Providers from './Providers';
import Router from './Router';

const App = () => {
  useEffect(() => {
    // Hide the loading message after the component mounts
    const hideLoading = () => {
      const loadingElement = document.getElementById('loading');
      if (loadingElement) {
        loadingElement.style.display = 'none';
      }
    };

    hideLoading();
  }, []);
  return (
    <StrictMode>
      <Providers>
        <Router />
      </Providers>
    </StrictMode>
  );
};

export default App;
