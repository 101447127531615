import { useEffect, useState } from 'react';

const textInputTypes = ['text', 'search', 'email', 'tel', 'url'];

const useDetectKeyboardOpen = (): boolean => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  useEffect(() => {
    const handleFocus = () => {
      const activeElement = document.activeElement as HTMLInputElement | null;
      if (activeElement?.tagName.toLowerCase() === 'input') {
        if (textInputTypes.includes(activeElement.type)) {
          setIsFocused(true);
        }
      }
    };

    const handleBlur = () => {
      setIsFocused(false);
    };

    document.addEventListener('focus', handleFocus, true);
    document.addEventListener('blur', handleBlur, true);

    return () => {
      document.removeEventListener('focus', handleFocus, true);
      document.removeEventListener('blur', handleBlur, true);
    };
  }, []);

  return isFocused;
};

export default useDetectKeyboardOpen;
